import styled from 'styled-components'

export const Title = styled.h1`
    /* color: gray; */
    font-size: 70px;
    text-align: center;
`

export const Subtitle = styled.p`
    color: gray;
    text-align: center;
    font-size: 35px;
`